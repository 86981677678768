import React from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import {
  MediumComponentContainer,
  PageContainer,
  UnderlinedLink,
} from "styles/global.style";
import {
  BlogCategoryBoxDescription,
  BlogCategoryBoxTitle,
  Category,
  CategoryList,
  StyledBlogCategoryBox,
} from "./index.style";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import Loop from "components/core/Loop";

interface CategoryLink {
  text: string;
  path: string;
}

interface BlogCategoriesBoxProps {
  id?: string;
  title?: string;
  description?: string;
  links: CategoryLink[];
}

const BlogCategoriesBox = (props: BlogCategoriesBoxProps) => {
  return (
    <StyledBlogCategoryBox id={props.id}>
      <MediumComponentContainer>
        <PageContainer>
          <Container>
            {props.title && (
              <Row>
                <Col>
                  <BlogCategoryBoxTitle level={2}>
                    {props.title}
                  </BlogCategoryBoxTitle>
                  {props.description && (
                    <BlogCategoryBoxDescription>
                      {props.description}
                    </BlogCategoryBoxDescription>
                  )}
                </Col>
              </Row>
            )}
            <Row justifyContent={"center"}>
              {props.links && (
                <CategoryList>
                  <Loop items={props.links}>
                    {(link) => (
                      <Category key={link.text}>
                        <UnderlinedLink as={SafeLink} to={link.path}>
                          {link.text}
                          <Icon type={"arrow-right"} size={16} />
                        </UnderlinedLink>
                      </Category>
                    )}
                  </Loop>
                </CategoryList>
              )}
            </Row>
          </Container>
        </PageContainer>
      </MediumComponentContainer>
    </StyledBlogCategoryBox>
  );
};

export default BlogCategoriesBox;
