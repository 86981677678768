import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import {
  Author,
  AuthorContent,
  AuthorImage,
  AuthorName,
  AuthorRole,
  CategoryContainer,
  Content,
  Description,
  Divider,
  Duration,
  HeadingContainer,
  ImageContainer,
  LatestPost,
  Metadata,
} from "styles/blog.style";
import { Col, Container, Row } from "styled-bootstrap-grid";
import SafeLink from "components/core/SafeLink";
import Heading from "components/core/Typography/Heading";
import { graphql, useStaticQuery } from "gatsby";
import { getReadingTime } from "utils/reading-time";
import { AllPostQueryResult } from "app-types/blog";
import BlogPost from "components/ui/base/BlogPost";
import { Title } from "components/core/Typography/Title";
import Loop from "components/core/Loop";
import BlogSubscribe from "components/ui/base/BlogSubscribe";
import { MediumComponentContainer, PageContainer } from "styles/global.style";
import { getBlogPathBySlug, getBlogPathByUri } from "utils/path";
import BlogPostsBox from "components/ui/base/BlogPostsBox";
import BlogCategoriesBox from "components/ui/base/BlogCategoriesBox";

export interface BlogProps {
  location: Location;
}

export default ({ location }: BlogProps) => {
  const { t } = useTranslation();

  const { allPostResult, allWpCategory }: AllPostQueryResult =
    useStaticQuery(graphql`
      query {
        allPostResult: allWpPost(sort: { fields: date, order: DESC }) {
          nodes {
            author {
              node {
                avatar {
                  url
                }
                name
                uri
                description
              }
            }
            categories {
              nodes {
                name
                slug
                uri
              }
            }
            date(formatString: "MMM DD, YYYY")
            uri
            slug
            title
            content
            seo {
              title
              metaDesc
            }
            smallFeaturedImage: featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      height: 300
                      width: 500
                    )
                  }
                }
              }
            }
            bigFeaturedImage: featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        allWpCategory {
          nodes {
            name
            slug
          }
        }
      }
    `);

  const latestPost = allPostResult.nodes[0];
  const categories = allWpCategory.nodes;

  const otherLatestPosts = [
    allPostResult.nodes[1],
    allPostResult.nodes[2],
    allPostResult.nodes[3],
    allPostResult.nodes[4],
  ];

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.blog.seo.title")}
        description={t("pages.blog.seo.description")}
        withoutTitleSuffix={true}
        image={{
          relativePath:
            "meta/customerly-blog-help-with-customer-service-marketing-automation.jpg",
          alt: "Customerly Blog | Help with Customer Support and Marketing Automation",
        }}
      />

      <LatestPost>
        <Container>
          <Row alignItems={"center"}>
            <Col sm={12} md={6}>
              <SafeLink to={getBlogPathBySlug(latestPost.slug)}>
                <ImageContainer
                  backgroundUrl={
                    latestPost.bigFeaturedImage.node.localFile.childImageSharp
                      .gatsbyImageData.images.fallback?.src
                  }
                />
              </SafeLink>
            </Col>
            <Col sm={12} md={6}>
              <Content>
                <Metadata>
                  <SafeLink
                    to={getBlogPathByUri(latestPost.categories.nodes[0].uri)}
                  >
                    {latestPost.categories.nodes[0].name}
                  </SafeLink>

                  <Divider>•</Divider>

                  <Duration>
                    {getReadingTime(latestPost.content)}{" "}
                    {t("pages.blog.index.minRead")}
                  </Duration>
                </Metadata>
                <HeadingContainer>
                  <SafeLink to={getBlogPathBySlug(latestPost.slug)}>
                    <Heading level={2} weight={600}>
                      {latestPost.title}
                    </Heading>
                  </SafeLink>
                </HeadingContainer>
                <Description>{latestPost.seo.metaDesc}</Description>
                <Author>
                  <AuthorImage>
                    <img
                      alt={latestPost.author.node.name}
                      src={latestPost.author.node.avatar.url}
                    />
                  </AuthorImage>
                  <AuthorContent>
                    <AuthorName>
                      <SafeLink
                        to={getBlogPathByUri(latestPost.author.node.uri)}
                      >
                        {latestPost.author.node.name}
                      </SafeLink>
                    </AuthorName>
                    <AuthorRole>
                      {latestPost.author.node.description}
                    </AuthorRole>
                  </AuthorContent>
                </Author>
              </Content>
            </Col>
          </Row>
        </Container>
      </LatestPost>

      <PageContainer>
        <BlogCategoriesBox
          title={t("pages.blog.index.title")}
          description={t("pages.blog.index.description")}
          links={categories.map((category) => ({
            text: category.name,
            path: `/blog/category/${category.slug}/`,
          }))}
        />

        <MediumComponentContainer>
          <CategoryContainer>
            <Row>
              <Col sm={12} md={6}>
                <Title>{t("pages.blog.index.latestPost")}</Title>
              </Col>
            </Row>

            <Row>
              <Loop items={otherLatestPosts}>
                {(otherLatestPost, idx) => (
                  <Col key={`${idx}-${otherLatestPost.uri}`} sm={12} md={6}>
                    <BlogPost post={otherLatestPost} />
                  </Col>
                )}
              </Loop>
            </Row>
          </CategoryContainer>
        </MediumComponentContainer>

        <BlogSubscribe condensed={false} utmCampaign={"blogHomePage"} />

        <BlogPostsBox
          title={t("pages.blog.index.CustomerService")}
          category={"customer-service"}
          postsPerRow={3}
          maxPosts={6}
          currentPostSlug={"/blog/"}
        />

        <BlogPostsBox
          title={t("pages.blog.index.marketingAutomation")}
          category={"marketing-automation"}
          postsPerRow={2}
          maxPosts={6}
          currentPostSlug={"/blog/"}
        />

        <BlogPostsBox
          title={t("pages.blog.index.customerSatisfaction")}
          category={"customer-satisfaction"}
          postsPerRow={3}
          maxPosts={3}
          currentPostSlug={"/blog/"}
        />

        <BlogPostsBox
          title={t("pages.blog.index.inspirational")}
          category={"inspiration"}
          postsPerRow={2}
          maxPosts={4}
          currentPostSlug={"/blog/"}
        />
      </PageContainer>
    </GlobalWrapper>
  );
};
