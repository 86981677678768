import styled from "styled-components";
import { StyledHeading } from "components/core/Typography/Heading/index.style";
import { media } from "styled-bootstrap-grid";
import Heading from "components/core/Typography/Heading";

const BlogCategoryBoxTitle = styled(Heading)``;

const BlogCategoryBoxDescription = styled.p``;

const CategoryList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px 32px;

  ${media.lg`
      gap: 8px 64px;
    `}
`;

const Category = styled.div`
  padding: 4px 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 2px;
    transition: all 0.3s ease-in-out;
    stroke: ${(props) => props.theme.accentColor};

    width: 16px;
    height: 16px;

    ${media.md`
      width: 16px;
      height: 16px;
    `}

    ${media.lg`
      width: 18px;
      height: 18px;
    `}
  }
`;

const StyledBlogCategoryBox = styled.section`
  ${StyledHeading} {
    font-weight: 600;
    line-height: 1.2;
    color: ${(props) => props.theme.titleColor};
    margin-bottom: 0.3em;
    margin-top: 0.75em;
  }

  text-align: center;
`;

export {
  BlogCategoryBoxTitle,
  BlogCategoryBoxDescription,
  StyledBlogCategoryBox,
  Category,
  CategoryList,
};
